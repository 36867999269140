import React from "react";
import "../../styles/index.scss";

export default function Footer() {
  return (
    <section className="section bg-light py-3">
      <div className="container">
        <div className="row">
          <div className="col-12 my-1 text-center">
            <p className="copy-rights text-muted mb-0">
              VOLKSWAGEN CENTRAL - 115 Lý Chính Thắng, Phường Võ Thị Sáu, Quận
              3, Tp HCM{" "}
            </p>
            <p className="copy-rights text-muted mb-0">
              2024 © vwcentral.vn Design by AnNK
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
