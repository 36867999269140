import React, { useState } from "react";
import "./index.scss";

export default function Technical() {
  const menuItems = [
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/115854100762min-15859182541.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g1-15854100764.gif",
      title: "CẢNH BÁO PHÍA TRƯỚC",
      content: "/audio/canh_bao_phia_truoc.mp3",
    },
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/215854101829min-15859182654.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g2-15854101822.gif",
      title: "CẢNH BÁO ĐIỂM MÙ",
      content: "/audio/canh_bao_diem_mu.mp3",
    },
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/315854102188min-15859182710.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g3-15854102182.gif",
      title: "CẢNH BÁO PHƯƠNG TIỆN PHÍA SAU",
      content: "/audio/canh_bao_phia_sau.mp3",
    },
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/415854102400min-15859182791.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g4-15854102407.gif",
      title: "HỆ THỐNG GIỮ LÀN ĐƯỜNG",
      content: "/audio/giu_lane.mp3",
    },
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/515854102630min-15859182863.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g5-15854102635.gif",
      title: "HỆ THỐNG HỖ TRỢ ĐỖ XE",
      content: "/audio/park.mp3",
    },
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/615854102892min-15859182948.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g6-15854102895.gif",
      title: "HỆ THỐNG KIỂM SOÁT HÀNH TRÌNH THÍCH ỨNG",
      content: "/audio/hanhtrinhthichung.mp3",
    },
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/715854103185min-15859183047.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g7-15854103183.gif",
      title: "KIỂM SOÁT CỰ LY ĐỖ XE",
      content: "/audio/culy.mp3",
    },
    {
      icon: "https://volkswagen-saigon.com.vn/thumb/90x70/2/upload/hinhanh/915854103664min-15859183228.png",
      image:
        "https://volkswagen-saigon.com.vn/upload/hinhanh/g9-15854103667.gif",
      title: "HỆ THỐNG KIỂM SOÁT ĐÈN CHIẾU",
      content: "/audio/light.mp3",
    },
  ];

  const [currentContent, setCurrentContent] = useState(menuItems[0]);

  // const loadContent = (index) => {
  //   setCurrentContent(menuItems[index]);
  //   toggleAudio();
  // };

  const [isPlaying, setPlaying] = useState(false);

  // const toggleAudio = () => {
  //   const audio = document.getElementById("audioElement");
  //   audio.currentTime = 0;
  //   if (isPlaying) {
  //     audio.pause();
  //   } else {
  //     audio.play();
  //   }

  //   setPlaying(!isPlaying);
  // };

  const loadContentAndPlayAudio = (index) => {
    const audio = document.getElementById("audioElement");
    setCurrentContent(menuItems[index]);

    // Pause and reset the audio when changing content
    audio.pause();
    audio.currentTime = 0;

    // Add an event listener for the 'loadedmetadata' event
    audio.addEventListener("loadedmetadata", () => {
      // Once the metadata has loaded, play the audio
      audio.play();
      setPlaying(true);
    });

    // Set the source after adding the event listener
    audio.src = menuItems[index].content;
  };

  return (
    <section className="client">
      <div className="container">
        <div className="row client-detail position-relative" style={{top:"-180px"}}>
          <h2 className="home-heading mb-4">
            <center>CÔNG NGHỆ TRÊN XE VOLKSWAGEN</center>
          </h2>

          <div className="menuButtons">
            {menuItems.map((item, index) => (
              <button
                key={index}
                onClick={() => loadContentAndPlayAudio(index)}
              >
                <img src={item.icon} alt={`Icon ${index + 1}`} />
              </button>
            ))}
          </div>

          <div className="content">
            <img src={currentContent.image} alt="Image" />
            <h2>{currentContent.title}</h2>

            <audio id="audioElement">
              <source src={currentContent.content} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </div>
    </section>
  );
}
