import React from "react";
import Header from "./components/header";
import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Technical from "./components/technical";
import Footer from "./components/footer/footer";

export default function App() {
  return (
    <>
      <Header />
      <Technical />
      <Footer/>
    </>
  );
}
