import React from "react";
import "../../styles/index.scss";

export default function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-custom sticky">
        <div className="container">
          {/* LOGO */}
          <a className="navbar-brand logo text-uppercase" href="index.html">
            <img
              src="https://volkswagen-saigon.com.vn/upload/company/favicon115854168073min-15859183428.png"
              style={{ width: "10%" }}
              alt="logo"
            />{" "}
            Volkswagen central
          </a>
        </div>
      </nav>

      <section className="section home bg-home bg-light" id="home">
        {/* <div>  */}
        <div className="container">
          <div className="row align-items-center pt-4">
            <div className="col-lg-6">
              <div className="home-content">
                <h2 className="home-heading mb-4">VOLKSWAGEN CENTRAL</h2>
                <p className="home-text text-muted mb-4">
                  📅 ĐỊA CHỈ: 115 Lý Chính Thắng, Phường Võ Thị Sáu, Quận 3, Tp
                  HCM.
                </p>
                <p className="home-text text-muted mb-4">
                  📲 LIÊN HỆ NGAY ĐỂ NHẬN ƯU ĐÃI:
                </p>
                <p className="home-text text-muted mb-4">
                  ☎️ Hotline : 0878 776 888
                </p>
                <div className="pt-2">
                  <a
                    href="https://www.facebook.com/lak.pro"
                    className="btn btn-sm btn-outline-custom mr-2"
                  >
                    LIÊN HỆ
                  </a>
                  {/* <span className="video-button">
                    <a
                      className="video-play-icon"
                      href="http://vimeo.com/99025203"
                    >
                      <span className="player-text ml-3 d-none d-sm-inline-block">
                        {" "}
                        Watch a Demo
                      </span>
                      <span className="btn-player ml-1">
                        <i className="pe-7s-play text-center text-dark" />
                      </span>
                    </a>
                  </span> */}
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 mt-3 mt-sm-0">
              <img
                src="https://themesdesign.in/injeto/layouts/images/home.svg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
}
